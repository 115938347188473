import { UserRoleType } from '../../../../models/User';
import {
  customerSupportUseCases,
  defaultUseCases,
  engineeringUseCases,
  hrUseCases,
  legalComplianceUseCases,
  marketingUseCases,
  productManagementUseCases,
  salesUseCases,
  talentUseCases,
} from './data';
import { UseCases } from './types';

export const useCasesMap = {
  [UserRoleType.ENGINEERING]: engineeringUseCases,
  [UserRoleType.CUSTOMER_SUPPORT]: customerSupportUseCases,
  [UserRoleType.SALES]: salesUseCases,
  [UserRoleType.MARKETING]: marketingUseCases,
  [UserRoleType.PRODUCT_MANAGEMENT]: productManagementUseCases,
  [UserRoleType.HR]: hrUseCases,
  [UserRoleType.LEGAL_COMPLIANCE]: legalComplianceUseCases,
  [UserRoleType.TALENT]: talentUseCases,
};

export const getUseCasesByUserRole = (useCaseType?: UserRoleType): UseCases => {
  return useCaseType ? { ...useCasesMap[useCaseType] } : { ...defaultUseCases };
};
