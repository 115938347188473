import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SidebarRoutes } from '../../../containers/SidebarContainer';
import { trackEvent } from '../../../extra/sharedMethods';
import { UserRoleType } from '../../../models/User';
import { updateOrg } from '../../../scripts/apis';
import { invokeFastApi } from '../../../scripts/apis/fastapi';
import { AnalyticsEvent } from '../../../scripts/constants/analytics-event';
import { MessageAction } from '../../../scripts/constants/message-action';
import { useGlobalState, useUserSafe } from '../../../scripts/hooks';
import { logError, logErrorWithData } from '../../../scripts/utils';
import { InviteMembersContent } from '../../admin/InviteMembersModal/InviteMembersContent';
import { useInviteMembers } from '../../admin/InviteMembersModal/useInviteMembers';
import { Modal } from '../../controls/ui/Modal/Modal';
import { UIButton } from '../../controls/ui/UIButton/UIButton';
import { OrgInfoSetup } from './OrgInfoSetup';
import {
  ReferralSourceType,
  UserReferralSourceSelect,
} from './UserReferralSourceSelect';
import { UserWorkRoleSelect } from './UserWorkRoleSelect';
import {
  isUserSetupDoneStoredInLocalStorage,
  setUserSetupDoneInLocalStorage,
} from './utils';

export const UserSetup: React.FC = () => {
  const userName = useGlobalState((state) => state.meta.userDisplayName);
  const orgName = useUserSafe((u) => u.orgByOrgId.name);
  const isUserAdmin = useUserSafe((u) => u.admin);
  const companyLogo = useUserSafe((u) => u.orgByOrgId.preferences.logo);
  const isUserFirstAdmin = useUserSafe((u) => u.onboardingState.first_admin);

  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [inputCompanyName, setInputCompanyName] = useState<string>(orgName);
  const [inputCompanyLogoUrl, setInputCompanyLogoUrl] = useState<
    string | undefined
  >(companyLogo);

  const [userWorkRole, setUserWorkRole] = useState<UserRoleType>(
    UserRoleType.ENGINEERING
  );

  const [selectedSource, setSelectedSource] = useState<ReferralSourceType>(
    ReferralSourceType.SEARCH_ENGINE
  );

  const [otherSource, setOtherSource] = useState('');

  const {
    selectedMembers,
    membersToSendNewInvite,
    membersToResendInvite,
    selectMember,
    removeMember,
    updateMemberRole,
    sendInvites,
  } = useInviteMembers();

  const isDoneDisabled =
    (selectedSource === ReferralSourceType.OTHER && !otherSource) ||
    (isUserAdmin ? inputCompanyName.trim().length === 0 : false);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setUserSetupDoneInLocalStorage(true);

    const referralSource =
      selectedSource === ReferralSourceType.OTHER
        ? otherSource
        : selectedSource;

    trackEvent(AnalyticsEvent.UserSetupFinished, {
      userWorkRole,
      referralSource,
    });

    try {
      if (
        isUserAdmin &&
        isUserFirstAdmin &&
        (companyLogo !== inputCompanyLogoUrl || orgName !== inputCompanyName)
      ) {
        await updateOrg({
          logo: inputCompanyLogoUrl,
          companyName: inputCompanyName,
        });
      }

      await invokeFastApi({
        method: 'PATCH',
        path: '/users/setup_status',
        body: {
          work_role: userWorkRole,
          referral_source: referralSource,
        },
        shouldRetry: true,
      });

      await sendInvites(false);
    } catch (error) {
      logError(error);
      logErrorWithData(new Error('Error while user setup'), {
        work_role: userWorkRole,
        referral_source: referralSource,
      });
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
        setShowModal(false);
        window.postMessage(MessageAction.UserSetupFinished, '*');
      }, 1000);
    }
  }, [
    inputCompanyLogoUrl,
    inputCompanyName,
    companyLogo,
    orgName,
    otherSource,
    selectedSource,
    userWorkRole,
    sendInvites,
    isUserAdmin,
    isUserFirstAdmin,
  ]);

  useEffect(() => {
    if (isUserSetupDoneStoredInLocalStorage()) {
      setShowModal(false);
    } else {
      history.push(SidebarRoutes.JitQA);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showModal) {
    return null;
  }

  const isFirstAdminSetup = isUserAdmin && isUserFirstAdmin;

  return (
    <Modal
      modalClasses="!w-[520px]"
      onClose={() => {
        // non closable modal
      }}
    >
      <div>
        <div className="max-h-[80vh] overflow-y-auto custom-scrollbar">
          <h2 className="text-2xl font-bold m-0">
            Hello, {userName?.split(' ')[0] ?? 'there'} 👋
          </h2>
          <div className="flex items-center gap-1 text-lg mt-2">
            {isFirstAdminSetup ? (
              "Let's set up your workspace"
            ) : (
              <>
                Join your team{' '}
                <img
                  alt="Organization Logo"
                  className="w-4 h-4"
                  src={companyLogo}
                />{' '}
                {orgName}!
              </>
            )}
          </div>
          {isFirstAdminSetup && (
            <div className="mt-6">
              <OrgInfoSetup
                companyLogoUrl={inputCompanyLogoUrl}
                companyName={inputCompanyName}
                setCompanyLogoUrl={setInputCompanyLogoUrl}
                setCompanyName={setInputCompanyName}
              />
            </div>
          )}
          <div className="mt-6">
            <UserWorkRoleSelect
              setUserWorkRole={setUserWorkRole}
              userWorkRole={userWorkRole}
            />
          </div>
          {isFirstAdminSetup && (
            <>
              <div className="mt-6">
                <UserReferralSourceSelect
                  otherSource={otherSource}
                  selectedSource={selectedSource}
                  setOtherSource={setOtherSource}
                  setSelectedSource={setSelectedSource}
                />
              </div>

              <div className="mt-6">
                <div className="block font-bold text-base mb-2">
                  Invite team members
                </div>
                <InviteMembersContent
                  canShowOptionsInBottom={
                    !(
                      membersToResendInvite.length ||
                      membersToSendNewInvite.length
                    )
                  }
                  doNotLoadMembers
                  headingSize="sm"
                  membersToResendInvite={membersToResendInvite}
                  membersToSendNewInvite={membersToSendNewInvite}
                  placeholder="Enter member's email to invite"
                  removeMember={removeMember}
                  selectMember={selectMember}
                  selectedMembers={selectedMembers}
                  updateMemberRole={updateMemberRole}
                />
              </div>
            </>
          )}
        </div>
        <footer className="flex justify-between mt-6">
          <div />
          <UIButton
            disabled={isDoneDisabled}
            onClick={onSubmit}
            processing={isSubmitting}
          >
            Done
          </UIButton>
        </footer>
      </div>
    </Modal>
  );
};
