import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import dashworksLogo from '../../assets/images/dashworks_logo.svg';
import { setSidebarNotificationsOpen } from '../../redux/sidebar/actions';
import { useDispatch } from '../../redux/store';
import { UserProfileIcon } from '../general/UserProfile/UserProfile';
import { useNotifications } from './NotificationsContext';
import {
  getDisplayableTime,
  NotificationsFeedItem,
  NotificationType,
} from './utils';

interface NotificationsCardProps {
  item: NotificationsFeedItem;
}

export const NotificationsCard = ({
  item,
}: NotificationsCardProps): JSX.Element => {
  const dispatch = useDispatch();
  const { markTopicNotificationsAsRead } = useNotifications();

  const closeNotificationsSidebar = useCallback(() => {
    dispatch(setSidebarNotificationsOpen(false));
  }, [dispatch]);

  const [displayableTime, setDisplayableTime] = useState(
    getDisplayableTime(item.createdAt)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayableTime(getDisplayableTime(item.createdAt));
    }, 60_000);

    return () => {
      clearInterval(interval);
    };
  }, [item.createdAt]);

  return (
    <NavLink
      className="text-xs px-4 py-4 text-inherit relative flex align-top border-b border-cloud-20 hover:bg-cloud-15 transition-all cursor-pointer"
      key={item.id}
      onClick={() => {
        if (!item.read) {
          markTopicNotificationsAsRead(item.topicId, true);
        }

        closeNotificationsSidebar();
      }}
      to={
        item.messageId
          ? `/topic/${item.topicId}/${item.messageId}`
          : `/topic/${item.topicId}`
      }
    >
      <div className="w-8 h-8 flex items-center justify-center mr-2">
        {item.notificationType === NotificationType.NOTIFY_RESEARCH ? (
          <UserProfileIcon icon={dashworksLogo} />
        ) : (
          <UserProfileIcon
            icon={item.sender.avatar ?? undefined}
            name={item.sender.name}
          />
        )}
      </div>

      <div>
        <div>
          {(() => {
            switch (item.notificationType) {
              case NotificationType.NOTIFY_TAGGED_USERS:
                return (
                  <div className="text-sm">
                    <span>{item.sender.name}</span> tagged you in{' '}
                    <span className="font-semibold">{item.topicTitle}</span>
                  </div>
                );
              case NotificationType.NOTIFY_BOT_ADMINS:
                return (
                  <div className="text-sm">
                    <span>{item.sender.name}</span> tagged{' '}
                    {item.managedBotDetails
                      ? `${item.managedBotDetails.icon} ${item.managedBotDetails.bot_name}`
                      : 'a bot managed by you'}{' '}
                    in <span className="font-semibold">{item.topicTitle}</span>
                  </div>
                );
              case NotificationType.NOTIFY_USER_ADDED_TO_TOPIC:
                return (
                  <div className="text-sm">
                    <span>{item.sender.name}</span> added you to{' '}
                    <span className="font-semibold">{item.topicTitle}</span>
                  </div>
                );
              case NotificationType.NOTIFY_RESEARCH:
                return (
                  <div className="text-sm">
                    {item.success ? (
                      <>
                        <span>Research on </span>
                        <span className="font-semibold">
                          {item.topicTitle}{' '}
                        </span>
                        <span>is ready.</span>
                      </>
                    ) : (
                      <>
                        <span>Something went wrong — research for </span>
                        <span className="font-semibold">
                          {item.topicTitle}{' '}
                        </span>
                        <span>couldn't be finished.</span>
                      </>
                    )}
                  </div>
                );
              default:
                return (
                  <div className="text-sm">
                    <span>{item.sender.name}</span> sent a new message in{' '}
                    <span className="font-semibold">{item.topicTitle}</span>
                  </div>
                );
            }
          })()}
          <div className="text-xs text-gray-50">{displayableTime}</div>
        </div>
        {!item.read && (
          <div className="absolute top-2 left-2 w-2 h-2 rounded-[50%] bg-mahogany-20" />
        )}
      </div>
    </NavLink>
  );
};
