import React, { FC, useState } from 'react';
import { InstallChromeExtensionDialog } from '../../components/controls/InstallExtensionDialog/chrome/InstallExtensionDialog';
import { UIButton } from '../../components/controls/ui/UIButton/UIButton';
import { InstallDialogProps } from '../../scripts/app';
import { useBoolState } from '../../scripts/hooks';
import { useExtensionObserver } from '../../scripts/hooks/extension';
import { isChrome } from '../../scripts/utils';
import { ConnectStep } from '../generic/Components';
import { SimpleAPIKeyInstall } from '../generic/SimpleAPIKeyInstall';

const handleInstallApp = () => {
  window.open('https://app.highspot.com/settings/developer/basics', '_blank');
};

export const AppInstallModal: FC<InstallDialogProps> = ({
  app,
  isOrg,
  onClose,
  open,
}) => {
  const [
    openInstallChromeExtensionDialog,
    setOpenInstallChromeExtensionDialog,
    setNotOpenInstallChromeExtensionDialog,
  ] = useBoolState(false);

  const [hasClickedInstall, setHasClickedInstall] = useState(false);
  const extensionInstalled = useExtensionObserver();
  const unsupportedBrowser = !isChrome;

  const handleInstallClick = () => {
    handleInstallApp();
    setHasClickedInstall(true);
  };

  return (
    <SimpleAPIKeyInstall
      app={app}
      credentialFields={[
        { name: 'api_key', placeholder: 'Enter API key' },
        { name: 'api_secret', placeholder: 'Enter API secret' },
      ]}
      credentialName="credentials"
      disableConnect={
        extensionInstalled || unsupportedBrowser || !hasClickedInstall
      }
      instructionSteps={
        <>
          <ConnectStep step={1} title="Install Dashworks Extension">
            <p>
              Dashworks connects to Highspot through our browser extension,
              which securely reads your Highspot cookies to search your Highspot
              workspace.
            </p>
            {unsupportedBrowser ? (
              <p className="text-mahogany-20">
                Dashworks extension is only supported on Chrome. Please use
                Chrome to install the extension.
              </p>
            ) : (
              <UIButton
                disabled={extensionInstalled}
                onClick={setOpenInstallChromeExtensionDialog}
                size="large"
              >
                {extensionInstalled ? 'Extension installed' : 'Install'}
              </UIButton>
            )}
          </ConnectStep>

          <ConnectStep step={2} title="Generate API credentials">
            <p>
              If you don't have API access, ask a workspace admin to grant it
              for you{' '}
              <a
                href="https://app.highspot.com/settings/company/api_access"
                rel="noreferrer"
                target="_blank"
              >
                here
              </a>
              .
            </p>
            <UIButton onClick={handleInstallClick} size="large">
              Open Highspot
            </UIButton>
          </ConnectStep>

          <InstallChromeExtensionDialog
            onClose={setNotOpenInstallChromeExtensionDialog}
            open={openInstallChromeExtensionDialog}
          />
        </>
      }
      isOrg={isOrg}
      onClose={onClose}
      open={open}
      stepCount={1}
    />
  );
};
